/* eslint-disable func-names */

import DobUtil from '@/services/Utils/DOB';

const registerValidateDOBDateRule = (yup: any) => {
	yup.addMethod(yup.string, 'validateDOBDate', function (errorMessage: string) {
		// @ts-ignore
		return this.test(`validateDate`, errorMessage, function () {
			// @ts-ignore
			const { path, createError } = this;
			// @ts-ignore
			const day = this.parent.dateInput;
			// @ts-ignore
			const month = this.parent.monthInput;
			// @ts-ignore
			const year = this.parent.yearInput;

			let valid = true;

			if (day && month && year) {
				valid = DobUtil.isValidDate(day, month, year);
			}

			return valid || createError({ message: errorMessage, path });
		});
	});
};

export { registerValidateDOBDateRule };
