import {
	add,
	addDays,
	addMonths,
	endOfDay,
	format,
	getDay,
	isAfter,
	isBefore,
	parse,
	startOfDay,
} from 'date-fns';
import { de, enGB, enIE, es, nl } from 'date-fns/locale';

import { SPAIN } from '@/constants/locales';

export type FirstRepaymentDateRestrictions = {
	filterDate: (date: Date) => boolean;
	maxDate: Date;
	minDate: Date;
};

const locales = {
	de,
	es,
	gb: enGB,
	ie: enIE,
	nl,
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const localeFormat = (date: number | Date, formatStr = 'PP') => {
	let locale: string = 'gb';

	if (typeof window?.localStorage !== 'undefined') {
		locale = (localStorage.getItem('locale') as string) || 'gb';
	}

	let formattedDate = format(date, formatStr, {
		// @ts-ignore
		locale: locales[locale],
	});

	if ([SPAIN].includes(locale))
		formattedDate = formattedDate
			.split(' ')
			.map((item) => capitalizeFirstLetter(item))
			.join(' ');

	return formattedDate;
};

const getDefaultRepaymentDateRestrictions = (): FirstRepaymentDateRestrictions => ({
	filterDate: (date: Date): boolean => {
		const day = getDay(date);
		return day !== 0;
	},
	maxDate: add(new Date(), { days: 40 }),
	minDate: startOfDay(add(new Date(), { days: 8 })),
});

// yyyy-MM-dd -> dd-MM-yyyy
const ISOtoFrontend = (date: string, separator: string = '-'): string => {
	try {
		const parsed = parse(date, 'yyyy-MM-dd', new Date());
		return localeFormat(parsed, `dd${separator}MM${separator}yyyy`);
	} catch (error) {
		return '';
	}
};

// yyyy-MM-dd -> dd MMM yyyy
const ISOtoHumanFriendly = (date: string): string => {
	try {
		const parsed = parse(date, 'yyyy-MM-dd', new Date());
		return localeFormat(parsed, 'dd MMM yyyy');
	} catch (error) {
		return '';
	}
};

// dd-MM-yyyy -> dd MMM yyyy
const FrontendtoHumanFriendly = (date: string): string => {
	try {
		const parsed = parse(date, 'dd-MM-yyyy', new Date());
		return localeFormat(parsed, 'dd MMM yyyy');
	} catch (error) {
		return '';
	}
};

// Date to -> E do LLL yyyy (Thu 22nd Dec 2022)
const DateToDetailedHumanFriendly = (date: Date): string => {
	try {
		return localeFormat(new Date(date), 'E do LLL yyyy');
	} catch (error) {
		return '';
	}
};

const DateToHumanFriendly = (date: Date): string => {
	try {
		return localeFormat(date, 'dd MMM yyyy');
	} catch (error) {
		return '';
	}
};

//  dd-MM-yyyy -> yyyy-MM-dd
const FrontendtoISO = (date: string): string => {
	try {
		const parsed = parse(date, 'dd-MM-yyyy', new Date());
		return localeFormat(parsed, 'yyyy-MM-dd');
	} catch (error) {
		return '';
	}
};

// ISO date -> dd MMM yyyy HH:mm:ss
const ISOtoCreated = (date: string): string => {
	try {
		const convertedDate = localeFormat(new Date(date), 'dd MMM yyyy HH:mm:ss');
		return `${convertedDate}`;
	} catch (error) {
		return '';
	}
};

//  dd-MM-yyyy -> Date
const FrontendToDate = (date: string): Date => {
	try {
		return endOfDay(parse(date, 'dd-MM-yyyy', new Date()));
	} catch (error) {
		return new Date();
	}
};

// dd/MM/yyyy -> Date
const TraditionalToDate = (date: string): Date => {
	try {
		return endOfDay(parse(date, 'dd/MM/yyyy', new Date()));
	} catch (error) {
		return new Date();
	}
};

//  yyyy-MM-dd -> Date
const ISOToDate = (date: string): Date => {
	try {
		return parse(date, 'yyyy-MM-dd', new Date());
	} catch (error) {
		return new Date();
	}
};

//  Date -> dd-MM-yyyy
const DateToFrontend = (date: Date, dateFormat = 'dd-MM-yyyy'): string => {
	try {
		return localeFormat(date, dateFormat);
	} catch (error) {
		return '';
	}
};

//  Date -> ISO
const DateToISO = (date: Date): string => {
	try {
		return localeFormat(date, 'yyyy-MM-dd');
	} catch (error) {
		return '';
	}
};

// DOB -> yyyy-MM-dd
const DOBToISO = (year: string, month: string, day: string): string => {
	try {
		return `${year}-${month}-${day}`;
	} catch (error) {
		return '';
	}
};

// DOB -> Date
const DOBToDate = (day: string, month: string, year: string): Date => {
	try {
		return endOfDay(parse(`${day}-${month}-${year}`, 'dd-MM-yyyy', new Date()));
	} catch (error) {
		return new Date();
	}
};

const isDateWithinRestrictions = (date: Date, maxDate: Date, minDate: Date) =>
	isBefore(date, startOfDay(addDays(maxDate, 1))) &&
	isAfter(date, startOfDay(minDate)) &&
	getDay(date) !== 0;

const isDateInInterval = (date: Date, maxDate: Date, minDate: Date) =>
	isBefore(date, endOfDay(addDays(maxDate, 1))) && isAfter(date, startOfDay(minDate));

const add30DaysToNow = () => {
	const addedDay = addDays(new Date(), 30);
	return format(addedDay, 'yyyy-MM-dd');
};

// FrontendDate -> FrontendDate + Added Months
const addNumberMonthToFrontendDate = (frontendDate: string, months: number) => {
	const date = FrontendToDate(frontendDate);
	const addedMonth = addMonths(date as Date, months);
	return FrontendtoHumanFriendly(DateToFrontend(addedMonth) as string);
};

const formatTime = (time: string) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [hour, minute, period] = time.split(/:|\s/);
	const formattedHour = parseInt(hour, 10) % 12 || 12;
	const formattedPeriod = period.toLowerCase();
	return `${formattedHour}${formattedPeriod}`;
};

const formatTimeRange = (timeRange: string) => {
	if (timeRange) {
		try {
			const [start, end] = timeRange.split(' - ');
			const formattedStart = formatTime(start);
			const formattedEnd = formatTime(end);
			return `${formattedStart} - ${formattedEnd}`;
		} catch (error) {
			console.error(error);
			return '';
		}
	} else {
		return '';
	}
};

// ISO -> Restricted dd-MM-yyyy
const dateToRestrictedOrInitial = (
	date: Date | undefined,
	maxDate: Date,
	minDate: Date,
	fallbackDate: Date
): string => {
	if (date) {
		return isDateWithinRestrictions(date, maxDate, minDate)
			? DateToFrontend(date || '')
			: DateToFrontend(fallbackDate);
	}
	return DateToFrontend(fallbackDate);
};

export {
	add30DaysToNow,
	addNumberMonthToFrontendDate,
	DateToDetailedHumanFriendly,
	DateToFrontend,
	DateToHumanFriendly,
	DateToISO,
	dateToRestrictedOrInitial,
	DOBToDate,
	DOBToISO,
	formatTimeRange,
	FrontendToDate,
	FrontendtoHumanFriendly,
	FrontendtoISO,
	getDefaultRepaymentDateRestrictions,
	isDateInInterval,
	isDateWithinRestrictions,
	ISOtoCreated,
	ISOToDate,
	ISOtoFrontend,
	ISOtoHumanFriendly,
	localeFormat,
	TraditionalToDate,
};
