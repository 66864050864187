import './index.d';

import * as yup from 'yup';

import { registerAgeMustOver18Rule } from '@/services/Forms/Validation/Rules/ageMustOver18Rule';
import { registerAmericanSocialSecurityNumberRule } from '@/services/Forms/Validation/Rules/americanSocialSecurityNumberRule';
import { registerDateWithinRestrictionsRule } from '@/services/Forms/Validation/Rules/dateWithinRestrictionsRule';
import { registerSpanishDNINumberValidationRule } from '@/services/Forms/Validation/Rules/spanishDNINumberValidationRule';
import { registerValidateDOBDateRule } from '@/services/Forms/Validation/Rules/validateDOBDateRule';

registerAgeMustOver18Rule(yup);
registerAmericanSocialSecurityNumberRule(yup);
registerDateWithinRestrictionsRule(yup);
registerSpanishDNINumberValidationRule(yup);
registerValidateDOBDateRule(yup);

export default yup;
