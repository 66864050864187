/* eslint-disable func-names */
import Dob from '@/services/Utils/DOB';

const registerAgeMustOver18Rule = (yup: any) => {
	// @ts-ignore
	yup.addMethod(yup.string, 'ageMustOver18', function (errorMessageLow, errorMessageHigh) {
		// @ts-ignore
		return this.test(`age-must-be-over-18`, errorMessageLow, function (value) {
			// @ts-ignore
			const { path, createError } = this;
			// @ts-ignore
			const day = this.parent.dateInput;
			// @ts-ignore
			const month = this.parent.monthInput;
			// @ts-ignore
			const year = this.parent.yearInput;

			const ageValidation = Dob.validateAge(value, day, month, year);

			const checkDayAndMonthExist = (messageHighError: string): string => {
				if (day && month) {
					return messageHighError;
				}
				return Dob.yearDifference(year) >= 120 ? messageHighError : errorMessageLow;
			};

			return (
				ageValidation.isValid ||
				createError({
					message: ageValidation.isLower
						? errorMessageLow
						: checkDayAndMonthExist(errorMessageHigh),
					path,
				})
			);
		});
	});
};

export { registerAgeMustOver18Rule };
