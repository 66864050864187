const validateAmericanSocialSecurityNumber = (value: string, errorMessage: string) => {
	const ssnPattern = /^(?!000|666|9\d\d)\d{3}([- ]?)\d{2}\1(?!0000)\d{4}$/;

	if (!value) {
		return { message: 'forms:ssnNumber.validation.required', status: false };
	}

	if (!ssnPattern.test(value)) {
		return { message: errorMessage, status: false };
	}

	// Length check for SSNs with or without dashes
	const ssnWithoutDashes = value.replace(/-/g, '');
	if (ssnWithoutDashes.length !== 9) {
		return { message: 'forms:ssnNumber.validation.invalidLength', status: false };
	}

	// Advanced checks
	const [areaNumber, groupNumber, serialNumber] = value.split(/\D/).map(Number);

	if (areaNumber < 1 || (areaNumber > 899 && areaNumber !== 666) || areaNumber > 999) {
		return { message: 'forms:ssnNumber.validation.invalidAreaNumber', status: false };
	}

	if (groupNumber < 1 || groupNumber > 99) {
		return { message: 'forms:ssnNumber.validation.invalidGroupNumber', status: false };
	}

	if (serialNumber < 1 || serialNumber > 9999) {
		return { message: 'forms:ssnNumber.validation.invalidSerialNumber', status: false };
	}

	return { message: '', status: true };
};

export { validateAmericanSocialSecurityNumber };
