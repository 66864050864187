import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldNamesMarkedBoolean } from 'react-hook-form/dist/types/form';

const getNestedObject = (nestedObj: any, pathArr: string[]) =>
	pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);

const resolveFormVariant = (
	fieldName: string,
	errors: FieldErrors,
	dirtyFields: FieldNamesMarkedBoolean<any>
) => {
	const fields = fieldName.split('.');
	const error = getNestedObject(errors, fields);
	if (error) {
		return 'error';
	}

	if (dirtyFields[fields[0]]) {
		return 'success';
	}

	return 'default';
};

export { resolveFormVariant };
