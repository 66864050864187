import { differenceInYears, isValid, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
const zeroPad19 = (num: number, places: number) => String(num).padStart(places, '19');
const zeroPad20 = (num: number, places: number) => String(num).padStart(places, '20');
const zeroPad200 = (num: number, places: number) => String(num).padStart(places, '200');

const convertNumber20Format = (dateValue: number): string => {
	if (dateValue === 10) {
		return zeroPad20(dateValue, 4);
	}
	return zeroPad200(dateValue, 4);
};

const convertNumberToTwoDecimal = (dateValue: string): string => {
	if (parseInt(dateValue, 10) >= 1 && parseInt(dateValue, 10) < 10) {
		return zeroPad(parseInt(dateValue, 10), 2);
	}

	return dateValue;
};

const validateYear = (year: string) => {
	const yearOfDate = parseInt(year, 10);

	if (yearOfDate >= 1 && yearOfDate <= 10 && yearOfDate !== 0) {
		return convertNumber20Format(yearOfDate);
	}

	if (yearOfDate >= 11 && yearOfDate <= 99 && yearOfDate !== 0) {
		return zeroPad19(yearOfDate, 4);
	}

	return String(yearOfDate);
};

const getYear = (): number => new Date().getFullYear();

const validateAgeOver18 = (checkYear: number): boolean => {
	const currentYear = getYear();
	return currentYear - checkYear >= 18 && currentYear - checkYear <= 120;
};

const isValidDate = (day: string, month: string, year: string) => {
	const parsed = parse(`${day}/${month}/${year}`, 'P', new Date(), { locale: enGB });
	return isValid(parsed);
};

const validateAge = (value: any, day: string, month: string, year: string) => {
	let isValidAge = value ? validateAgeOver18(parseInt(value, 10)) : false;
	let isLower = false;

	if (day && month && year) {
		const dateDifference = differenceInYears(
			new Date(),
			new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
		);
		isValidAge = dateDifference >= 18 && dateDifference <= 120;
		isLower = dateDifference < 18;
	}

	return { isLower, isValid: isValidAge };
};

const yearDifference = (value: string): number => getYear() - parseInt(value, 10);

export default {
	convertNumberToTwoDecimal,
	getYear,
	isValidDate,
	validateAge,
	validateYear,
	yearDifference,
};
