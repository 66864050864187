/* eslint-disable func-names */

import { validateAmericanSocialSecurityNumber } from '@/services/Forms/Validation/Methods/validateAmericanSocialSecurityNumber';

const registerAmericanSocialSecurityNumberRule = (yup: any) => {
	yup.addMethod(
		yup.string,
		'americanSocialSecurityNumberValidate',
		function (errorMessage: string) {
			// @ts-ignore
			return this.test('americanSocialSecurityNumberValidate', errorMessage, function (value: any) {
				// @ts-ignore
				const { path, createError } = this;

				if (value) {
					const validationResult = validateAmericanSocialSecurityNumber(value, errorMessage);

					if (!validationResult.status) {
						return createError({
							message: validationResult.message,
							path,
						});
					}
				} else {
					return createError({ message: errorMessage, path });
				}

				return true;
			});
		}
	);
};

export { registerAmericanSocialSecurityNumberRule };
