import { isNIE, isNIF } from 'better-dni';

const validateSpanishDNINumber = (value: string, errorMessage: string) => {
	const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
	const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
	const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;

	if (isNIF(value) || isNIE(value)) {
		return { message: '', status: true };
	}

	// NIE section
	if (value.charAt(0).match(/[a-zA-Z]/)) {
		if (value.length < 8) {
			return { message: 'forms:dniNumber.validation.nieIncorrectChar', status: false };
		}

		if (!nieRexp.test(value)) {
			return { message: 'forms:dniNumber.validation.nieIncorrectChar', status: false };
		}

		const nie = value.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');
		const letter = value.substring(value.length - 1);

		const charIndex = parseInt(nie.substring(0, 8), 10) % 23;

		if (validChars.charAt(charIndex) === letter) return { message: '', status: true };
		// security letter error message
		return { message: errorMessage, status: false };
	}

	if (value.charAt(0).match(/[0-9]/)) {
		// DNI section
		if (value.length < 8) {
			return { message: 'forms:dniNumber.validation.dniIncorrectChar', status: false };
		}

		if (!nifRexp.test(value)) {
			return { message: 'forms:dniNumber.validation.dniIncorrectChar', status: false };
		}

		const nif = value;
		const letter = value.substring(value.length - 1);

		const charIndex = parseInt(nif.substring(0, 8), 10) % 23;

		if (validChars.charAt(charIndex) === letter) return { message: '', status: true };
		// security letter error message
		return { message: errorMessage, status: false };
	}
	// required error message
	return { message: 'forms:dniNumber.validation.required', status: false };
};
export { validateSpanishDNINumber };
