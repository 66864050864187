/* eslint-disable func-names */
import { validateSpanishDNINumber } from '@/services/Forms/Validation/Methods/validateSpanishDNINumber';

const registerSpanishDNINumberValidationRule = (yup: any) => {
	yup.addMethod(yup.string, 'spanishDNINumberValidation', function (errorMessage: string) {
		// @ts-ignore
		return this.test(`dni-number-validation`, errorMessage, function (value: any) {
			// @ts-ignore
			const { path, createError } = this;

			if (value) {
				if (!validateSpanishDNINumber(value, errorMessage).status) {
					// console.log(dniNumberValidate(value, errorMessage));
					return createError({
						message: validateSpanishDNINumber(value, errorMessage).message,
						path,
					});
				}
				return true;
			}
			return createError({ message: errorMessage, path });
		});
	});
};

export { registerSpanishDNINumberValidationRule };
