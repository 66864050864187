/* eslint-disable func-names */

import { FrontendToDate, isDateWithinRestrictions } from '@/services/Utils/Date';

const registerDateWithinRestrictionsRule = (yup: any) => {
	yup.addMethod(
		yup.string,
		'dateWithinRestrictions',
		function (errorMessage: string, maxDate: Date, minDate: Date) {
			// @ts-ignore
			return this.test(`date-within-restrictions`, errorMessage, function (value: any) {
				// @ts-ignore
				const { path, createError } = this;

				if (value) {
					return (
						isDateWithinRestrictions(FrontendToDate(value), maxDate, minDate) ||
						createError({ message: errorMessage, path })
					);
				}
				return createError({ message: errorMessage, path });
			});
		}
	);
};

export { registerDateWithinRestrictionsRule };
